import { VyneButton } from '@vynedental/design-system'
import { Tooltip } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'

import { SearchBar, SearchStates } from 'ui'

import {
  refreshSentAttachmentGrid,
  refreshUnsentAttachmentGrid,
} from '../_attachmentGridServices'
import AttachmentStatusFilter from '../_AttachmentStatusFilter'
import { AttachmentStatusFilters } from '../../AttachmentsTypes'
import { useAttachmentsContext } from '../../Context/AttachmentsContext'

import './AttachmentsGridToolbar.scss'

import {
  FileDownloadOutlined,
  PrintOutlined,
  SyncOutlined,
  ViewColumnOutlined,
} from '@mui/icons-material'

interface AttachmentsGridToolbarProps {
  downloadTable: () => void
  filters: AttachmentStatusFilters
  printTable: () => void
  searchInput: SearchStates
  sentDates: [Date, Date]
  setFilters: Dispatch<SetStateAction<AttachmentStatusFilters>>
  setSearchInput: Dispatch<SetStateAction<SearchStates>>
}

const AttachmentsGridToolbar: FC<AttachmentsGridToolbarProps> = ({
  downloadTable,
  filters,
  printTable,
  searchInput,
  sentDates,
  setFilters,
  setSearchInput,
}) => {
  const {
    activeTab,
    authentication,
    facilityId,
    gridLoading,
    setAttachments,
    setGridLoading,
    setSentAttachments,
    setShowingSettings,
    getSortedAttachments,
  } = useAttachmentsContext()

  const onGridRefresh = async () => {
    setGridLoading(true)
    activeTab === 'Unsent'
      ? await refreshUnsentAttachmentGrid(
          facilityId,
          setAttachments,
          getSortedAttachments,
        )
      : await refreshSentAttachmentGrid(
          facilityId,
          setSentAttachments,
          sentDates,
          getSortedAttachments,
        )
    setGridLoading(false)
  }

  return (
    <section className='sa-toolbar__actions'>
      {activeTab === 'Unsent' && (
        <AttachmentStatusFilter
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {activeTab === 'Unsent' && (
        <SearchBar
          disabled={gridLoading}
          setState={setSearchInput}
          state={searchInput}
        />
      )}
      <section className='flex items-center gap-100'>
        <Tooltip
          title='Update'
          mouseEnterDelay={0.5}
        >
          <VyneButton
            className='grid-control-button'
            dataTestId='grid-toolbar-print-icon'
            icon={<SyncOutlined />}
            onClick={onGridRefresh}
          />
        </Tooltip>
        <Tooltip
          title='Print'
          mouseEnterDelay={0.5}
        >
          <VyneButton
            className='grid-control-button'
            dataTestId='grid-toolbar-print-icon'
            icon={<PrintOutlined />}
            onClick={printTable}
          />
        </Tooltip>
        <Tooltip
          title='Download'
          mouseEnterDelay={0.5}
        >
          <VyneButton
            className='grid-control-button'
            dataTestId='grid-toolbar-print-icon'
            icon={<FileDownloadOutlined />}
            onClick={downloadTable}
          />
        </Tooltip>
        <Tooltip
          title='Columns'
          mouseEnterDelay={0.5}
        >
          <VyneButton
            className='grid-control-button'
            dataTestId='grid-toolbar-print-icon'
            icon={<ViewColumnOutlined />}
            onClick={() => setShowingSettings(true)}
          />
        </Tooltip>
      </section>
    </section>
  )
}

export default AttachmentsGridToolbar
