import type { UploadProps } from 'antd'

import { CloseOutlined } from '@ant-design/icons'
import { message, Upload, UploadFile } from 'antd'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import { ConvertImageRequest } from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  ConvertImageS3Bytes,
  DocumentStoragePath,
  GetImageFromS3,
  UploadImageToS3,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { dataURLtoFile } from '../../../utilities/general'
import { ImageDataProps } from '../AttachmentsTypes'
import { useAttachmentsContext } from '../Context/AttachmentsContext'

import './FileUpload.scss'

import { FileUploadOutlined } from '@mui/icons-material'
import { VyneButton, VyneModal } from '@vynedental/design-system'

const { Dragger } = Upload

const acquisitionType = 'File-Upload-Trellis-SA'
const allowedFileTypes = [
  '.tif',
  '.pjp',
  '.xbm',
  '.jxl',
  '.svgz',
  '.jpg',
  '.jpeg',
  '.ico',
  '.tiff',
  '.gif',
  '.svg',
  '.jfif',
  '.webp',
  '.png',
  '.bmp',
  '.pjpeg',
  '.avi',
]

type FileUploadProps = {
  setImageDataProps: React.Dispatch<React.SetStateAction<ImageDataProps>>
  imageData: ImageDataProps[]
  setImageData: Dispatch<SetStateAction<ImageDataProps[]>>
}

const FileUpload: FC<FileUploadProps> = ({
  setImageDataProps,
  imageData,
  setImageData,
}) => {
  const [visibleFileUploadDialog, setVisibleFileUploadDialog] =
    useState<boolean>(false)
  const [files, setFiles] = useState<Array<UploadFile>>([])
  const [fileUploadButtonEnabled, setFileUploadButtonEnabled] =
    useState<boolean>(false)
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false)

  const defaultOrientation =
    localStorage.getItem('standalone-attachment-image-orientation') || 'Right'
  const { authentication, facilityId } = useAttachmentsContext()

  const validateFileType = ({ name }: UploadFile) => {
    const fileExtension = name.substring(name.lastIndexOf('.')).toLowerCase()
    return allowedFileTypes.includes(fileExtension)
  }

  const beforeFileUpload = async (file: UploadFile) => {
    if (!validateFileType(file)) {
      message.error(`${file.name} does not have a valid file type extension`)
      return false
    }

    setFiles([file])
    setFileUploadButtonEnabled(true)

    return false
  }

  const clearFileUpload = () => {
    setFileUploadButtonEnabled(false)
    setFileUploadLoading(false)
    setFiles([])
  }

  const uploadFile = async () => {
    setFileUploadLoading(true)

    files
      .filter((file: any) => !file.validationErrors)
      .forEach(async (file: UploadFile) => {
        // Get file with bytes
        const fileTmp = new File(
          [new Blob([file as any])],
          file.name + '.jpg',
          { type: 'image/jpeg' },
        )
        const rowKey = new Date().getTime().toString()
        const rowId = imageData.length + 1

        // Handle tiff image conversions
        if (file != null && file.type === 'image/tiff') {
          // Get presigned path for temp storage of current image file
          await DocumentStoragePath(
            facilityId,
            parseInt(authentication.CustomerId),
          )
            .then(async (storageResponse) => {
              // Upload to s3
              await UploadImageToS3(
                storageResponse.data.documentPath,
                fileTmp,
              ).then(async (uploadResponse) => {
                // Get converted file back as s3 path
                const convertImageRequest: ConvertImageRequest = {
                  fileName: storageResponse.data.documentName,
                  destType: 'jpg',
                  facilityId: facilityId,
                }
                await ConvertImageS3Bytes(
                  convertImageRequest,
                  parseInt(authentication.CustomerId),
                ).then(async (convertResponse) => {
                  const image = await GetImageFromS3(
                    convertResponse.data.documentPath,
                  )
                  const imageFile = dataURLtoFile(
                    'data:image/jpeg;base64,' + image,
                    convertResponse.data.documentName,
                  )
                  const dateTaken = new Date().toString()

                  setImageDataProps({
                    imageData: imageFile,
                    orientation: defaultOrientation,
                    imageDataThumbnail: URL.createObjectURL(imageFile),
                    acquisitionType,
                    rowKey,
                    rowId,
                    imageChanged: true,
                    dateTaken: dateTaken,
                  })

                  setImageData([
                    ...imageData,
                    {
                      imageData: imageFile,
                      orientation: defaultOrientation,
                      imageDataThumbnail: URL.createObjectURL(imageFile),
                      acquisitionType,
                      rowKey,
                      rowId,
                      imageChanged: true,
                      dateTaken: dateTaken,
                    },
                  ])
                })
              })
            })
            .catch((error) => {
              message.error('Failed to upload file: ' + file.name)
              throw error
            })
        }
        // Handle all other image types
        else {
          const dateTaken = new Date().toString()

          setImageDataProps({
            imageData: fileTmp,
            orientation: defaultOrientation,
            imageDataThumbnail: URL.createObjectURL(fileTmp),
            acquisitionType,
            rowKey,
            rowId,
            imageChanged: true,
            dateTaken: dateTaken,
          })

          setImageData([
            ...imageData,
            {
              imageData: fileTmp,
              orientation: defaultOrientation,
              imageDataThumbnail: URL.createObjectURL(fileTmp),
              acquisitionType,
              rowKey,
              rowId,
              imageChanged: true,
              dateTaken: dateTaken,
            },
          ])
        }

        setVisibleFileUploadDialog(false)
        setFileUploadLoading(false)
        clearFileUpload()
      })
  }

  const componentProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: allowedFileTypes.join(', '),
    onRemove: clearFileUpload,
  }

  return (
    <>
      <VyneButton
        dataTestId='file-upload-open-modal-button'
        icon={<FileUploadOutlined />}
        onClick={() => {
          setVisibleFileUploadDialog(true)
        }}
      >
        Upload
      </VyneButton>
      <VyneModal
        dataTestId='standalone-attachments-file-upload-modal'
        title={'File Upload'}
        className='sa-file-upload'
        onCancel={() => {
          setVisibleFileUploadDialog(false)
          clearFileUpload()
        }}
        destroyOnClose={true}
        open={visibleFileUploadDialog}
        footer={null}
      >
        <div>
          <Dragger
            {...componentProps}
            fileList={files}
            showUploadList={false}
            beforeUpload={beforeFileUpload}
            disabled={files.length > 0}
          >
            {files.length === 0 ? (
              <>
                <p className='ant-upload-text'>
                  {files.length === 0
                    ? 'Drag and drop a file, or click here to select an image.'
                    : files[0].name}
                </p>
                <p className='ant-upload-drag-icon'>
                  {files.length === 0 && <FileUploadOutlined />}
                </p>
              </>
            ) : (
              <div className='sa-file-upload__list'>
                <Upload
                  fileList={files}
                  showUploadList={{ removeIcon: <CloseOutlined /> }}
                  onRemove={clearFileUpload}
                />
              </div>
            )}
          </Dragger>
          <section className='sa-file-upload--actions'>
            <VyneButton
              dataTestId='file-upload-button'
              disabled={!fileUploadButtonEnabled}
              loading={fileUploadLoading}
              onClick={uploadFile}
              type='primary'
            >
              Upload
            </VyneButton>
          </section>
        </div>
      </VyneModal>
    </>
  )
}

export default FileUpload
