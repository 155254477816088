import { Observable, ObservableObject } from '@legendapp/state'
import { Dispatch, SetStateAction } from 'react'
import { QueuedStatusSummary, SummaryCardsContainerItem } from 'ui'

import { ClaimGridState } from '../claimTypes'

import CheckCircleIcon from 'trellis:assets/check-circle-icon.svg?react'
import ExclamationCircleIcon from 'trellis:assets/exclamation-circle-icon.svg?react'
import InboxIcon from 'trellis:assets/inbox-icon.svg?react'
import { OfflineBoltOutlined } from '@mui/icons-material'
import { LDFlags$ } from 'trellis:state/globalState'

type ClaimSummaryCardItems = {
  failed?: number
  hold?: number
  needsAttention?: number
  ready?: number
  total?: number
  warning?: number
  realtime?: number
}

export const handleActiveSummaryCard = (
  state: ClaimGridState,
  activeSummaryCard: Observable<string | number>,
) => {
  switch (state.Filters.Config.Status) {
    case '':
    case null:
    case undefined:
      activeSummaryCard.set('default')
      break
    case 'Failed Validation,Pending,Other Coverage':
      activeSummaryCard.set('attention')
      break
    case 'In Queue':
      activeSummaryCard.set('ready')
      break
    case 'Needs Attention':
      activeSummaryCard.set('needs attention')
      break
    case 'Pending Patient Response':
      activeSummaryCard.set('pending')
      break
    case 'Eligible':
      activeSummaryCard.set('eligible')
      break
    case 'RealtimeCarriers':
      activeSummaryCard.set('realtime')
      break
    default:
      activeSummaryCard.set('')
      break
  }
}

export const handleSummaryFilter = (
  filter: string | number,
  activeSummaryCard: Observable<string | number>,
  state$: ObservableObject<ClaimGridState>,
  setState: Dispatch<SetStateAction<ClaimGridState>>
) => {
  const copy = { ...state$.peek() }
  copy.ActiveTab = 'Unsent'
  copy.Key = ++copy.Key
  copy.Filters.CurrentPage = 1

  if (filter === 'reset' || activeSummaryCard.peek() === filter) {
    delete copy.Filters.Config['Status']
    delete copy.Filters.Config['RealtimeCarriers']
  } else if (filter === 'attention') {
    copy.Filters.Config['Status'] = 'Failed Validation,Pending,Other Coverage'
    delete copy.Filters.Config['RealtimeCarriers']
  } else if (filter === 'ready') {
    copy.Filters.Config['Status'] = 'In Queue'
    delete copy.Filters.Config['RealtimeCarriers']
  } else if (filter === 'needs attention') {
    copy.Filters.Config['Status'] = 'Needs Attention'
    delete copy.Filters.Config['RealtimeCarriers']
  } else if (filter === 'pending') {
    copy.Filters.Config['Status'] = 'Pending Patient Response'
    delete copy.Filters.Config['RealtimeCarriers']
  } else if (filter === 'eligible') {
    copy.Filters.Config['Status'] = 'Eligible'
    delete copy.Filters.Config['RealtimeCarriers']
  } else if (filter === 'realtime') {
    delete copy.Filters.Config['Status']
    copy.Filters.Config['RealtimeCarriers'] = 'true'
  }

  state$.set(copy)
  setState(copy)
}

export const getClaimSummaryCardItems = (
  queuedStatusSummary: QueuedStatusSummary,
  activeSummaryCard: Observable<string | number>,
  state$: ObservableObject<ClaimGridState>,
  setState: Dispatch<SetStateAction<ClaimGridState>>
): Promise<SummaryCardsContainerItem[]> => {
  const currentState = state$.peek()
  console.log('Summary Cards - Current State:', currentState)
  console.log('Summary Cards - RealTimeCarriers:', currentState.RealTimeCarriers)
  const flags = LDFlags$.get()
  
  const summary: ClaimSummaryCardItems = {
    failed: 0,
    hold: 0,
    needsAttention: 0,
    ready: 0,
    total: queuedStatusSummary.total,
    warning: 0,
    realtime: currentState.RealTimeCarriers
  }

  queuedStatusSummary?.summary?.forEach((item) => {
    switch (item.Status) {
      case 'Hold':
        summary.hold = item.Count
        break
      case 'Failed Validation':
        summary.failed = item.Count
        summary.needsAttention = summary.needsAttention + item.Count
        break
      case 'Other Coverage':
      case 'Pending':
      case 'Rejected':
      case 'Needs Attention':
      case 'NeedsAttention':
        summary.needsAttention = summary.needsAttention + item.Count
        break
      case 'Accepted':
      case 'In Process':
      case 'In Queue':
        summary.ready = item.Count
        break
    }
  })

  const summaryCardsData: SummaryCardsContainerItem[] = [
    {
      action: () => handleSummaryFilter('reset', activeSummaryCard, state$, setState),
      color: 'blue',
      icon: <InboxIcon />,
      id: 'default',
      summary: `Holding ${summary?.hold}`,
      title: 'All Unsent',
      total: summary?.total,
      testId: 'summary-default',
    },
    {
      action: () => handleSummaryFilter('attention', activeSummaryCard, state$, setState),
      color: 'red',
      icon: <ExclamationCircleIcon />,
      id: 'attention',
      summary: `Failed Validation ${summary?.failed}`,
      title: 'Needs Attention',
      total: summary?.needsAttention,
      testId: 'summary-attention',
    },   
    {
      action: () => handleSummaryFilter('realtime', activeSummaryCard, state$, setState),
      color: 'orange',
      icon: <OfflineBoltOutlined className="realtime-icon" />,
      id: 'realtime',
      summary: 'Send for Real-time Response',
      title: 'Real-time Claims',
      total: currentState.RealTimeCarriers,
      testId: 'summary-realtime',
      showCard: flags?.trellisRealtimeLayoutUpdates,
    }, 
    {
      action: () => handleSummaryFilter('ready', activeSummaryCard, state$, setState),
      color: 'green',
      icon: <CheckCircleIcon />,
      id: 'ready',
      summary: 'Ready to Send',
      title: 'Ready',
      total: summary?.ready,
      testId: 'summary-ready',
    }
  ]

  return Promise.resolve(summaryCardsData)
}
