import { CloseOutlined } from '@ant-design/icons'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { message, Upload, UploadFile, UploadProps } from 'antd'
import React, { FC, useState } from 'react'

import {
  GetAttachmentImageStoragePathConversion,
  UploadImageToS3,
} from '../../../../api/attachment/attachmentApi'
import { ConvertImageS3Bytes } from '../../../../api/attachment/attachmentApiService'

import '../../../Attachments/AttachmentView/FileUpload.scss'

import { IImageCaptureClaimAttachment } from '../../../attachmentsShared/shared/shared.interface'
import { canvasImageConversion } from '../../../attachmentsShared/shared/sharedFunctions'
import { ImageCaptureMethod, ImageQuality } from '../../attachment.interface'

import '../../../Attachments/AttachmentView/FileUpload.scss'

import { FileUploadOutlined } from '@mui/icons-material'

import {
  FileExtensions,
  MimeTypes,
} from '../../../attachmentsShared/shared/shared.types'

const { Dragger } = Upload

const allowedFileTypes = [
  '.tif',
  '.pjp',
  '.xbm',
  '.jxl',
  '.svgz',
  '.jpg',
  '.jpeg',
  '.ico',
  '.tiff',
  '.gif',
  '.svg',
  '.jfif',
  '.webp',
  '.png',
  '.bmp',
  '.pjpeg',
  '.avi',
]

const FileUpload: FC<IImageCaptureClaimAttachment> = ({
  updateAttachmentImage,
}) => {
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false)
  const [files, setFiles] = useState<UploadFile<any>[]>([])
  const [fileUploadButtonEnabled, setFileUploadButtonEnabled] =
    useState<boolean>(false)
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false)

  const uploadFile = async () => {
    setFileUploadLoading(true)
    try {
      const uploadFiles: UploadFile<any>[] = files.filter(
        (file: any) => !file.validationErrors,
      )
      for (const uploadFile of uploadFiles) {
        if (uploadFile == null) {
          return
        }
        const filename =
          (uploadFile.name.substring(0, uploadFile.name.lastIndexOf('.')) ||
            uploadFile.name) + FileExtensions.JPG
        const fileTmp = new File([new Blob([uploadFile as any])], filename)
        const isNewImage: boolean = true
        const imageDateTaken: Date = new Date()

        if (
          uploadFile.type != null &&
          uploadFile.type !== MimeTypes.ImageTiff &&
          uploadFile.type !== MimeTypes.ImageJpeg
        ) {
          const convertedFile: File = await canvasImageConversion(
            fileTmp,
            filename,
            MimeTypes.ImageJpeg,
            ImageQuality.MaxUnity,
          )
          updateAttachmentImage(
            {
              isNewImage,
              imageData: convertedFile,
              imageDateTaken,
              imageCaptureMethod: ImageCaptureMethod.FileUpload,
            },
            false,
          )
        } else if (
          uploadFile.type != null &&
          uploadFile.type === MimeTypes.ImageTiff
        ) {
          // Get presigned path
          await GetAttachmentImageStoragePathConversion().then(
            async (storageResponse) => {
              // Upload to s3
              await UploadImageToS3(
                storageResponse.data.storagePath,
                fileTmp,
              ).then(async () => {
                // Get converted file back passing in s3 path
                await ConvertImageS3Bytes(
                  storageResponse.data.imageName,
                  'jpg',
                ).then((convertResponse) => {
                  updateAttachmentImage(
                    {
                      isNewImage,
                      imageData: convertResponse,
                      imageDateTaken,
                      imageCaptureMethod: ImageCaptureMethod.FileUpload,
                    },
                    false,
                  )
                })
              })
            },
          )
        } else {
          updateAttachmentImage(
            {
              isNewImage,
              imageData: fileTmp,
              imageDateTaken,
              imageCaptureMethod: ImageCaptureMethod.FileUpload,
            },
            false,
          )
        }
      }
    } finally {
      clearFileUpload()
      setVisibleDialog(false)
    }
  }

  const validateFileType = ({ name }: UploadFile) => {
    const fileExtension = name
      .toLocaleLowerCase()
      .substring(name.lastIndexOf('.'))
    return allowedFileTypes.includes(fileExtension)
  }

  const beforeFileUpload = async (file: UploadFile) => {
    if (!validateFileType(file)) {
      message.error(`${file.name} does not have a valid file type extension`)
      return false
    }

    setFiles([file])
    setFileUploadButtonEnabled(true)

    return false
  }

  const clearFileUpload = () => {
    setFileUploadButtonEnabled(false)
    setFileUploadLoading(false)
    setFiles([])
  }

  const componentProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: allowedFileTypes.join(', '),
    onRemove: clearFileUpload,
  }

  return (
    <>
      <VyneButton
        dataTestId='file-upload-button'
        icon={<FileUploadOutlined />}
        onClick={() => {
          setVisibleDialog(true)
        }}
      >
        Upload
      </VyneButton>
      {visibleDialog && (
        <VyneModal
          dataTestId='claim-attachments-file-upload-modal'
          title={'File Upload'}
          className='sa-file-upload'
          onCancel={() => {
            setVisibleDialog(false)
            clearFileUpload()
          }}
          destroyOnClose={true}
          open={visibleDialog}
          footer={null}
        >
          <div>
            <Dragger
              {...componentProps}
              fileList={files}
              showUploadList={false}
              beforeUpload={beforeFileUpload}
              disabled={files.length > 0}
            >
              {files.length === 0 ? (
                <>
                  <p className='ant-upload-text'>
                    {files.length === 0
                      ? 'Drag and drop a file, or click here to select an image.'
                      : files[0].name}
                  </p>
                  <p className='ant-upload-drag-icon'>
                    {files.length === 0 && <FileUploadOutlined />}
                  </p>
                </>
              ) : (
                <div className='sa-file-upload__list'>
                  <Upload
                    fileList={files}
                    showUploadList={{ removeIcon: <CloseOutlined /> }}
                    onRemove={clearFileUpload}
                  />
                </div>
              )}
            </Dragger>
            <section className='sa-file-upload--actions'>
              <VyneButton
                dataTestId='file-upload-modal-upload-button'
                disabled={!fileUploadButtonEnabled}
                loading={fileUploadLoading}
                onClick={uploadFile}
                type='primary'
              >
                Upload
              </VyneButton>
            </section>
          </div>
        </VyneModal>
      )}
    </>
  )
}

export default FileUpload
